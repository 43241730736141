
/* MINIFY THIS - classie and step forms */
        ( function( window ) {

        'use strict';

        // class helper functions from bonzo https://github.com/ded/bonzo

        function classReg( className ) {
          return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
        }

        // classList support for class management
        // altho to be fair, the api sucks because it won't accept multiple classes at once
        var hasClass, addClass, removeClass;

        if ( 'classList' in document.documentElement ) {
          hasClass = function( elem, c ) {
            return elem.classList.contains( c );
          };
          addClass = function( elem, c ) {
            elem.classList.add( c );
          };
          removeClass = function( elem, c ) {
            elem.classList.remove( c );
          };
        }
        else {
          hasClass = function( elem, c ) {
            return classReg( c ).test( elem.className );
          };
          addClass = function( elem, c ) {
            if ( !hasClass( elem, c ) ) {
              elem.className = elem.className + ' ' + c;
            }
          };
          removeClass = function( elem, c ) {
            elem.className = elem.className.replace( classReg( c ), ' ' );
          };
        }

        function toggleClass( elem, c ) {
          var fn = hasClass( elem, c ) ? removeClass : addClass;
          fn( elem, c );
        }

        var classie = {
          // full names
          hasClass: hasClass,
          addClass: addClass,
          removeClass: removeClass,
          toggleClass: toggleClass,
          // short names
          has: hasClass,
          add: addClass,
          remove: removeClass,
          toggle: toggleClass
        };

        // transport
        if ( typeof define === 'function' && define.amd ) {
          // AMD
          define( classie );
        } else {
          // browser global
          window.classie = classie;
        }

        })( window );

        ;( function( window ) {
          

          var transEndEventNames = {
              'WebkitTransition': 'webkitTransitionEnd',
              'MozTransition': 'transitionend',
              'OTransition': 'oTransitionEnd',
              'msTransition': 'MSTransitionEnd',
              'transition': 'transitionend'
            },
            transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
            support = { transitions : Modernizr.csstransitions };

          function extend( a, b ) {
            for( var key in b ) { 
              if( b.hasOwnProperty( key ) ) {
                a[key] = b[key];
              }
            }
            return a;
          }

          function StepsForm( el, options ) {
            this.el = el;
            this.options = extend( {}, this.options );
              extend( this.options, options );
              this._init();
              this.reinit = function() {
                  this._reinit();
              };
              this.reset = function() {
                  this._resetToFirstField();
              };

          }

          StepsForm.prototype.options = {
            onSubmit : function() { return false; }
          };
          StepsForm.prototype._resetToFirstField = function() {
              var _allQuestions = this.el.querySelectorAll('ol.questions > li');
              this.current = 0;
              this.questions = [].slice.call(this.el.querySelectorAll('ol.questions > li'));
              this.questionsCount = this.questions.length;
              for (var i = _allQuestions.length; i--;) {
                  classie.removeClass(_allQuestions[i], 'current');
              }
              this.currentNum = this.questionStatus.querySelector('span.number-current');
              this.currentNum.innerHTML = Number(this.current + 1);
              this.totalQuestionNum = this.questionStatus.querySelector('span.number-total');
              this.totalQuestionNum.innerHTML = this.questionsCount;
              this.progress.style.width = this.current * (100 / this.questionsCount) + '%';
              this.isFilled = false;
              this._clearError();
          };
          StepsForm.prototype._reinit = function() {
              this.current = 0;
              this.questions = [].slice.call(this.el.querySelectorAll('ol.questions > li'));
              this.questionsCount = this.questions.length;
              classie.addClass(this.questions[0], 'current');
              this.currentNum = this.questionStatus.querySelector('span.number-current');
              this.currentNum.innerHTML = Number(this.current + 1);
              this.totalQuestionNum = this.questionStatus.querySelector('span.number-total');
              this.totalQuestionNum.innerHTML = this.questionsCount;
          };
          StepsForm.prototype._init = function() {
            // current question
            this.current = 0;

            // questions
            this.questions = [].slice.call( this.el.querySelectorAll( 'ol.questions > li' ) );
            // total questions
            this.questionsCount = this.questions.length;
            // show first question
            classie.addClass( this.questions[0], 'current' );
            
            this.ctrlNext = this.el.querySelector( 'button.next-button' );

            // progress bar
            this.progress = this.el.querySelector( 'div.progress' );
            
            // question number status
            this.questionStatus = this.el.querySelector( 'span.number' );
            // current question placeholder
            this.currentNum = this.questionStatus.querySelector( 'span.number-current' );
            this.currentNum.innerHTML = Number( this.current + 1 );
            // total questions placeholder
            this.totalQuestionNum = this.questionStatus.querySelector( 'span.number-total' );
            this.totalQuestionNum.innerHTML = this.questionsCount;

            // error message
            this.error = this.el.querySelector( 'span.error-message' );
            
            // init events
            this._initEvents();


          };

          StepsForm.prototype._initEvents = function() {
            var self = this,
              // first input
              firstElInput = this.questions[ this.current ].querySelector( 'input' ),
              // focus
              onFocusStartFn = function() {
                firstElInput.removeEventListener( 'focus', onFocusStartFn );
                classie.addClass( self.ctrlNext, 'show' );
              };

            // show the next question control first time the input gets focused
            firstElInput.addEventListener( 'focus', onFocusStartFn );

            // show next question
            this.ctrlNext.addEventListener( 'click', function( ev ) { 
              ev.preventDefault();
              self._nextQuestion(); 
            } );

            // pressing enter will jump to next question
            document.addEventListener( 'keydown', function( ev ) {
              var keyCode = ev.keyCode || ev.which;
              // enter
              if( keyCode === 13 ) {
                ev.preventDefault();
                self._nextQuestion();
              }
            } );

            // disable tab
            this.el.addEventListener( 'keydown', function( ev ) {
              var keyCode = ev.keyCode || ev.which;
              // tab
              if( keyCode === 9 ) {
                ev.preventDefault();
              } 
            } );
          };

          StepsForm.prototype._nextQuestion = function() {
            if( !this._validade() ) {
              return false;
            }

            // check if form is filled
            if( this.current === this.questionsCount - 1 ) {
              this.isFilled = true;
            }

            // clear any previous error messages
            this._clearError();

            // current question
            var currentQuestion = this.questions[ this.current ];

            // increment current question iterator
            ++this.current;

            // update progress bar
            this._progress();

            if( !this.isFilled ) {
              // change the current question number/status
              this._updateQuestionNumber();

              // add class "show-next" to form element (start animations)
              classie.addClass( this.el, 'show-next' );

              // remove class "current" from current question and add it to the next one
              // current question
              var nextQuestion = this.questions[ this.current ];
              classie.removeClass( currentQuestion, 'current' );
              classie.addClass( nextQuestion, 'current' );
            }

            // after animation ends, remove class "show-next" from form element and change current question placeholder
            var self = this,
              onEndTransitionFn = function( ev ) {
                if( support.transitions ) {
                  this.removeEventListener( transEndEventName, onEndTransitionFn );
                }
                if( self.isFilled ) {
                  self._submit();
                }
                else {
                  classie.removeClass( self.el, 'show-next' );
                  self.currentNum.innerHTML = self.nextQuestionNum.innerHTML;
                  self.questionStatus.removeChild( self.nextQuestionNum );
                  // force the focus on the next input
                  nextQuestion.querySelector( 'input' ).focus();
                }
              };

            if( support.transitions ) {
              this.progress.addEventListener( transEndEventName, onEndTransitionFn );
            }
            else {
              onEndTransitionFn();
            }
          };

          // updates the progress bar by setting its width
          StepsForm.prototype._progress = function() {
            this.progress.style.width = this.current * ( 100 / this.questionsCount ) + '%';
          };

          // changes the current question number
          StepsForm.prototype._updateQuestionNumber = function() {
            // first, create next question number placeholder
            this.nextQuestionNum = document.createElement( 'span' );
            this.nextQuestionNum.className = 'number-next';
            this.nextQuestionNum.innerHTML = Number( this.current + 1 );
            // insert it in the DOM
            this.questionStatus.appendChild( this.nextQuestionNum );
          };

          // submits the form
          StepsForm.prototype._submit = function() {
            this.options.onSubmit( this.el );
          };

          // TODO (next version..)
          // the validation function
          StepsForm.prototype._validade = function() {
              // current question´s input
              var inputvalidate = $('li.current').find('input');
              var countQuestions = $('li.current').parent().find('li').length;
              if(countQuestions === 5){
                if(inputvalidate.attr('id') !== 'q5' && inputvalidate.attr('id') !== 'q3'){
                  if(inputvalidate.hasClass('wpcf7-file') && inputvalidate.val() === ''){
                    return false;
                  }
                  if(false === inputvalidate.parsley().validate()){
                    return false;
                  }
                  if (true === inputvalidate.parsley().validate()){
                    return true;
                  } 
                } else {
                  return true;
                }
              }
              else {
                if(inputvalidate.attr('id') !== 'q4'){
                  if(inputvalidate.hasClass('wpcf7-file') && inputvalidate.val() === ''){
                    return false;
                  }
                  if(false === inputvalidate.parsley().validate()){
                    return false;
                  }
                  if (true === inputvalidate.parsley().validate()){
                    return true;
                  } 
                } else {
                  return true;
                }
              }




          };

          // TODO (next version..)
          StepsForm.prototype._showError = function( err ) {
            var message = '';
            switch( err ) {
              case 'EMPTYSTR' : 
                message = 'Please fill the field before continuing';
                break;
              case 'INVALIDEMAIL' : 
                message = 'Please fill a valid email address';
                break;
              // ...
            }
            this.error.innerHTML = message;
            classie.addClass( this.error, 'show' );
          };

          // clears/hides the current error message
          StepsForm.prototype._clearError = function() {
            classie.removeClass( this.error, 'show' );
          };

          // add to global namespace
          window.StepsForm = StepsForm;

        })( window );


(function($) {
  var App = {
    infinitePosts: function(){
            if($('.filtering-container').length){
              var mixer = mixitup('.filtering-container');
              var state = mixer.getState();
            }

            $('.filtering-container').on('mixStart', function(event){
                setTimeout(function() {
                  $(event.detail.futureState.targets).each(function() {
                    $(this).removeClass('right');
                    $(this).find('.filtering-container').removeClass('justify-content-end');
                    $(this).find('.second').removeClass('pull-sm-6');
                  });
                }, 200);
                setTimeout(function() {
                  var counter = 0;
                  $(event.detail.futureState.show).each(function() {
                    var elem = this;
                    counter++;
                    if(counter%2!==0){
                      $(elem).addClass('right');
                      $(elem).find('.filtering-container').addClass('justify-content-end');
                      $(elem).find('.second').addClass('pull-sm-6');
                    }

                  });
                }, 200);


            });
            $('.filtering-container').on('mixEnd', function(){
              $(window).resize();
            });


            var infinite = new Waypoint.Infinite({
              element: $('.infinite-load')[0],
              items: '.load-new',
              more: '.more__news',
              loadingClass: 'loading',
              onAfterPageLoad:  function() {
                App.lazyInit();
                if(mixer){
                  mixer.forceRefresh();
                  state = mixer.getState();
                  mixer.filter(state.activeFilter.selector);
                }
              }
            });
    },
    modalsLoad: function() {
        var ieVersion = (function() { if (new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null) { return parseFloat( RegExp.$1 ); } else { return false; } })();
        var isIE = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
        if(ieVersion){
          var ieVersionclass = 'ie' + ieVersion;
          $('html').addClass(ieVersionclass).addClass('iexplorer');

          $(document).on('opened', '.remodal', function () {
            $(window).resize();
            $('img').trigger('appear');
          });
        } else if(isIE) {
          $('html').addClass('ie11').addClass('iexplorer');
          $(document).on('opened', '.remodal', function () {
            $(window).resize();
            $('img').trigger('appear');
          });
        }
    },
    mapsLoad: function() {
      if($('#map-eden').length){
        // L.mapbox.accessToken = 'pk.eyJ1IjoiaXNtYWVsc2FleiIsImEiOiJjaXhzcjd0OW8wMDEzMnFzeTBveGh1OTl2In0.Obb1dNskvcOYwCmJg2yoBw';
        mapboxgl.accessToken = 'pk.eyJ1IjoiaXNtYWVsc2FleiIsImEiOiJjaXhzcjd0OW8wMDEzMnFzeTBveGh1OTl2In0.Obb1dNskvcOYwCmJg2yoBw';


        var map = new mapboxgl.Map({
            container: 'map-eden',
            style: 'mapbox://styles/ismaelsaez/cixspx2in000t2ro5rmzkde1f',
            center: [-1.387024, 54.0278472],
            zoom: 1
        });
        
        var geojson = {
          type: 'FeatureCollection',
          features: [
            {
              "type": "Feature",
              "properties": {
                "description": '<div class="title">Eden Search<\/div><div class="address"><p>Suite E, 1st Floor, Oakgate House, 25 Market Place<\/p><p>Wetherby, LS22 6LQ<\/p><p>United Kingdom<\/p><\/div><a href="https://www.google.com/maps/dir//Eden+Search+%26+Select,+25+Market+Pl,+Wetherby+LS22+6LQ/@53.9284361,-1.3873289,19.5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48794f12727acd7d:0x4ed5041870f498db!2m2!1d-1.387039!2d53.928288" target="_blank">Get directions</a>',
                "iconSize": [50, 50],
              },
              "geometry": {
                "type": 'Point',
                "coordinates": [
                  -1.387038,
                  53.928289
                ],
              },
            },
            // {
            //   "type": "Feature",
            //   "properties": {
            //     "description": '<div class="title">Eden Search<\/div><div class="address"><p>33 St James\'s Square<\/p><p>London, SW1Y 4JS<\/p><p>United Kingdom<\/p><\/div><a href="https://www.google.com/maps/dir//33+St+James\'s+Square,+St.+James\'s,+London+SW1Y+4JS/@51.5075463,-0.1361771,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x487604d12672d8c5:0xd70f774c2490e48a!2m2!1d-0.1339831!2d51.507543" target="_blank">Get directions</a>',
            //     "iconSize": [50, 50],
            //   },
            //   "geometry": {
            //     "type": 'Point',
            //     "coordinates": [
            //       -0.133982,
            //       51.507544
            //     ],
            //   },
            // }
          ]
        };

        geojson.features.forEach(function(marker) {
          var el = document.createElement('div');
          el.className = 'marker';
          el.style.width = marker.properties.iconSize[0] + 'px';
          el.style.height = marker.properties.iconSize[1] + 'px';

          var popup = new mapboxgl.Popup()
            .setLngLat(marker.geometry.coordinates)
            .setHTML(marker.properties.description)
            .addTo(map);
              


          el.addEventListener('click', function(e) {
            map.flyTo({
                center: marker.geometry.coordinates,
                zoom: 15,
                bearing: 0,
                speed: 0.8,
                curve: 1,
                easing: function (t) {
                    return t;
                }
            });


          });
           

          new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(popup) 
          .addTo(map);
           
        });



        // map.on('click', 'places', function (e) {
        //   var coordinates = e.features[0].geometry.coordinates.slice();
        //   var description = e.features[0].properties.description;
           
        //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        //   }
           


        //   new mapboxgl.Popup()
        //     .setLngLat(coordinates)
        //     .setHTML(description)
        //     .addTo(map);
        // });
        var nav = new mapboxgl.NavigationControl();
        map.addControl(nav, 'top-left');


        map.flyTo({
            center: [-4.9798584, 53.0278472],
            zoom: 5,
            bearing: 0,
            speed: 0.6,
            curve: 1,
            easing: function (t) {
                return t;
            }
        });
        map.scrollZoom.disable();


      }
    },
    sliderNews: function(){
        $('.slidernews').slick({
          infinite: true,
          dots: false,
          arrows: false,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 3500,
          slidesToShow: 1,
          pauseOnHover: false
        });
    },
    sliderHome: function(){
      if ( $('.hero__main').length ) {
        $('.slider__home').slick({
          infinite: true,
          dots: false,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 8000,
          slidesToShow: 1,
          pauseOnHover: false
        });
        $('.navigation .arrow_left').click(function(){
          $('.slider__home').slick('slickPrev');
        });

        $('.navigation .arrow_right').click(function(){
          $('.slider__home').slick('slickNext');
        });
      }
    },
    sliderCases: function(){
      if ( $('.caseshome__section').length ) {
        $('.caseshome__section .second').slick({
          infinite: true,
          dots: false,
          fade: true,
          arrows: false,
          cssEase: 'linear',
          draggable: false,
          autoplay: true,
          adaptiveHeight: true,
          autoplaySpeed: 5000,
          asNavFor: '.caseshome__section .first-slider',
          slidesToShow: 1,
          pauseOnHover: false
        });
        $('.caseshome__section .first-slider').slick({
          infinite: true,
          dots: true,
          fade: true,
          arrows: false,
          draggable: false,
          cssEase: 'linear',
          autoplay: true,
          adaptiveHeight: true,
          autoplaySpeed: 5000,
          asNavFor: '.caseshome__section .second',
          customPaging : function() {
              return '<span class="dot"></span>';
          },
          appendDots:$('.caseshome__section .flex-md-top'),
          slidesToShow: 1,
          pauseOnHover: false
        });
        $('.www__section .init').slick({
          infinite: true,
          dots: false,
          fade: true,
          arrows: false,
          draggable: false,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          pauseOnHover: false
        });
        $('.caseshome__section .item').hover(function(){
          $('.caseshome__section').toggleClass('active');
        });
        $('.caseshome__section a.inner').hover(function(){
          $('.caseshome__section').toggleClass('active');
        });
        
        $('.www__section .first').hover(function(){
          $('.www__section').toggleClass('active');
        });
        $('.www__section .second').hover(function(){
          $('.www__section').toggleClass('active');
        });

      }
    },
    herosParallax: function() {
        
      var introSection = $('.page-header .text'),
        introSectionHeight = introSection.height(),
        //change scaleSpeed if you want to change the speed of the scale effect
        scaleSpeed = 1,
        //change opacitySpeed if you want to change the speed of opacity reduction effect
        opacitySpeed = 0.7; 
      
      var MQ = 0;
      //assign a scale transformation to the introSection element and reduce its opacity
      function animateIntro () {
          var scrollPercentage = ($(window).scrollTop()/introSectionHeight).toFixed(2);
          var YValue = ($(window).height())* ($(window).scrollTop() / $(document).height());
          if( $(window).scrollTop() < introSectionHeight) {
            TweenLite.to(introSection, 0.1, {opacity: ( 1 - scrollPercentage*opacitySpeed)}); 
          }
            TweenLite.to(introSection, 0.05, {y: YValue*scaleSpeed + "px"}); 
      }
      //bind the scale event to window scroll if window width > $MQ (unbind it otherwise)
      function triggerAnimation(){
        if($(window).width()>= MQ) {
          $(window).on('scroll touchmove', function(){
            //The window.requestAnimationFrame() method tells the browser that you wish to perform an animation- the browser can optimize it so animations will be smoother
            window.requestAnimationFrame(animateIntro);
          });
        } else {
          $(window).off('scroll');
        }
      }
      triggerAnimation();
      $(window).on('resize', function(){
        triggerAnimation();
      });
    },
    newsSticky: function() {
        var sidebarheight, mainheight;
        var cushion = 0; 
        function measureheight() {
            sidebarheight = $('.sticky-box').outerHeight() + cushion;
            mainheight = $('.blog-container').outerHeight();
            if (mainheight - sidebarheight > 0 && sidebarheight < $(window).height()) {
                $('.blog-container').waypoint(function(direction) {
                    $('.sticky-box').toggleClass('stuck', direction === 'down');
                });
                $('.getin_touch').waypoint(function(direction) {
                    $('.sticky-box').toggleClass('at-end', direction === 'down');
                }, {
                    offset: function() {
                        return sidebarheight;
                    }
                });
            } else {
                $().waypoint('destroy');
            }
        }
        measureheight();
        $(window).resize(measureheight);

    },
    socialSharer: function() {
        $('.social-share').click(function(e){
          e.preventDefault();
          window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=420,width=420');
        });
    },    
    headerSticky: function(){

      var wow = new WOW(
        {
          boxClass:     'onshow',  
          animateClass: 'isNow', 
          offset:       75,        
          mobile:       true,       
          live:         true,     
          callback:     function(box) {
          }
        }
      );
      wow.init();
      $('*[data-scroll-top]').click(function(e){
        e.preventDefault();
        $('html, body').animate({
          scrollTop: 0
        }, 670);
      });

      // var sticky = new Waypoint.Sticky({
      //   element: $('header.banner')[0]
      // });

      // jQuery(document).ready(function($){

      //   var mainHeader = $('header.banner'),
      //     secondaryNavigation = $('.cd-secondary-nav'),
      //     headerHeight = mainHeader.height();
        
      //   set scrolling variables
      //   var scrolling = false,
      //     previousTop = 0,
      //     currentTop = 0,
      //     scrollDelta = 50,
      //     scrollOffset = 150;
      //   function checkSimpleNavigation(currentTop) {
      //       if(currentTop <= scrollDelta){
      //         mainHeader.removeClass('slide');
      //       }
      //       if (previousTop - currentTop > scrollDelta) {
      //         if(currentTop > scrollDelta){
      //           mainHeader.addClass('slide');
      //         }
      //         mainHeader.removeClass('is-hidden');
      //       } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
      //         mainHeader.addClass('is-hidden');
      //         setTimeout(function(){
      //           mainHeader.addClass('slide');
      //         },300);
      //       }
      //   }


      //   function autoHideHeader() {
      //     var currentTop = $(window).scrollTop();
      //     checkSimpleNavigation(currentTop);
      //     previousTop = currentTop;
      //     scrolling = false;
      //   }

      //   $(window).on('scroll', function(){
      //     if( !scrolling ) {
      //       scrolling = true;
      //       setTimeout(autoHideHeader, 250);
      //     }
      //   });

      //   $(window).on('touchmove', function(){
      //     if( !scrolling ) {
      //       scrolling = true;
      //       setTimeout(autoHideHeader, 250);
      //     }
      //   });
      //   $(window).on('resize', function(){
      //     headerHeight = mainHeader.height();
      //   });

      // });
    },
    lazyInit: function() {
      $('*[data-original]').lazyload({
        threshold : 20,
        effect : "fadeIn"
      });
      if($(window).width() < 1025){
        $(window).trigger('scroll');
        $('*[data-original]').trigger('appear');
      }
    },
    footerScript: function() {
        // var scriptEls = document.getElementsByTagName( 'script' );
        // var thisScriptEl = scriptEls[scriptEls.length - 1];
        // var scriptPath = thisScriptEl.src;
        // var gettemplatedir.path = scriptPath.substr(0, scriptPath.lastIndexOf( '/' )-13 );
        var containerPP = $('.formpp-modal .inner');
       
        // RUN FORM

          $(document).on('change','input[type=file]', function(){
           var fileName = $(this).val();
           if(fileName === ''){
            fileName = '&nbsp;';
           } else{
            fileName = fileName.substr(fileName.lastIndexOf("\\") + 1);
           }

           $('label.file').html(fileName);
          });
     
          $('form.subscription .icon').click(function(){
            $( "form.subscription" ).submit();
          });

          var uploadCV = $('[data-modal-id=formpp]').remodal({
            hashTracking: true,
            closeOnOutsideClick: false
          });

          $('.formpp-modal').find('.final-message').click(function(){
              uploadCV.close();
              $('.simform').removeClass('active-form');
          });

          $('*[data-open]').click(function(){
            var $urlLoad = '';
            if($(this).attr('data-open') === 'arrangecb'){
               $urlLoad = gettemplatedir.path + '/partials/forms/arrangecb.php';
            } else if($(this).attr('data-open') === 'uploadcv'){
               $urlLoad = gettemplatedir.path + '/partials/forms/uploadcv.php?urlpost=' + gettemplatedir.currenturl;
            }
            if (!containerPP.hasClass('loaded')){
              $.ajax({
                  type : 'post',
                  url : $urlLoad,
                  success : function( html ) {
                      var form =  $(html);
                      containerPP.html( form ).addClass('loaded');

                      setTimeout(function(){
                        var theForm = document.getElementById( 'formpp' );
                        if(theForm !== null){
                            var _stepforms = _stepforms || new StepsForm( theForm, {
                              onSubmit : function(form) {
                                $('#formpp').submit();
                              }
                            });
                        }

                        uploadCV.open();

                        $('.formpp-modal').find('.final-message').click(function(){
                            uploadCV.close();
                            _stepforms.reset();
                            containerPP.removeClass('loaded').empty();
                        });
                        $(document).on('closed', '.remodal', function (e) {
                            _stepforms.reset();
                            containerPP.removeClass('loaded').empty();
                        });

                      },200);
                  }
              });
            } else{
              uploadCV.open();
            }
          });
    },
    menuMobile: function() {
           $.fn.disableScroll = function () {
             window.oldScrollPos = $(window).scrollTop();
             $(window).on('scroll.scrolldisabler', function (event) {
               $(window).scrollTop( window.oldScrollPos );
               event.preventDefault();
               return false;
             });
           };
           $.fn.enableScroll = function () {
               $(window).off('scroll.scrolldisabler');
           };
           var triggerBttn = $( "*[data-trigger-navigation]" ),
           menucontainer = $( '*[data-navigation-overlay]' ),
           linkBttn = menucontainer.find('li.menu-item a' ),
           isMenuOpen = false;


           function toggleMenu() {

             if( isMenuOpen ) {
               $('.elements').removeClass('appear');
               $("body").enableScroll();
               $('body').unbind('touchmove');
               menucontainer.removeClass('opened');
               triggerBttn.removeClass('is-active');
             }
             else {
               $("body").disableScroll();
               $('body').bind('touchmove', function(e) {
                  e.preventDefault();
               }); 
               menucontainer.addClass('opened');
               $('.elements').addClass('appear');
               triggerBttn.addClass('is-active');

             }
             
             isMenuOpen = !isMenuOpen;
           }


           function initEventsMenu() {
             triggerBttn.click(function(){
                 toggleMenu();
             });
           }

           function init() {
             initEventsMenu();
           }

            $(document).keyup(function(e) {
              if( isMenuOpen ) {
                  if (e.keyCode === 27) {
                    toggleMenu();
                  }
              }
            });

            linkBttn.click(function(e){
              if($(this).parent('li' ).hasClass('has-subpages')){
                e.preventDefault();
              } else{
                toggleMenu();
              }
            });


           init();
    },
    allScripts: function(){
        App.lazyInit();
    },

  };


  var Sage = {

    'common': {
      init: function() {
        App.newsSticky();
        App.modalsLoad();
        App.sliderNews();
        App.sliderCases();
        App.sliderHome();
        App.menuMobile();
        App.allScripts();
        App.headerSticky();
        App.footerScript();
        App.mapsLoad();
        App.herosParallax();
        App.socialSharer();
        App.infinitePosts();
      },
      finalize: function() {

      }
    }

  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery);
